import React from "react";
import Rfb from "../images/r-fb.svg";
import Rtw from "../images/r-tw.svg";
import Ryt from "../images/r-yt.svg";
import Rin from "../images/r-in.svg";
import Rig from "../images/r-ig.svg";
import Rsp from "../images/r-sp.svg";
import Riw from "../images/i-whatsapp.svg";

const Footer = ({ parametros }) => {
  const parametrosUsados = parametros.filter((p) => p.master_parametro);

  const enlacesRelacionados = parametrosUsados.filter(
    (p) => p.master_parametro.key === "MA-MEDIOS-INSTITUCIONALES-PUCP"
  );

  const redesSociales = parametrosUsados.filter(
    (p) => p.master_parametro.key === "MA-REDES-SOCIALES"
  );

  const enlacesGenerales = parametrosUsados.filter(
    (p) => p.master_parametro.key === "MA-ENLACES-GENERAL"
  );

  const terminosycondiciones = enlacesGenerales.find(
    (e) => e.key === "ENLACE-TERMINOS-Y-CONDICIONES"
  );

  const enviaTuEvento = enlacesGenerales.find(
    (e) => e.key === "envia-tu-evento"
  );

  return (
    <footer id="footer" className="footer">
      <div className="container-1200 w-container">
        <div className="footer-flex-container">
          {/* <div className="div-wrap-footer">
            <h2 className="footer-heading">Subscríbete</h2>
            <div className="w-form">
              <form id="email-form" name="email-form" data-name="Email Form">
                <label htmlFor="EMAIL_SUB-2" className="field-label-footer">
                  Recibe nuestro boletín en tu correo electrónico
                </label>
                <input
                  type="email"
                  className="text-field w-input"
                  maxLength="256"
                  name="EMAIL_SUB-2"
                  data-name="EMAIL SUB 2"
                  placeholder="correo@gmail.com"
                  id="EMAIL_SUB-2"
                  required=""
                />
                <input
                  type="submit"
                  value="Subscribirse"
                  data-wait="Espere ..."
                  className="btn-blue w-button"
                />
              </form>
              <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div className="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
          </div> */}
          <div className="div-wrap-footer">
            <h2 className="footer-heading">enlaces relacionados</h2>
            <ul className="list-footer w-list-unstyled">
              {enlacesRelacionados.map((e) => (
                <li key={e.id}>
                  <a
                    href={e.valor}
                    target="_blank"
                    rel="noreferrer"
                    className="footer-link"
                  >
                    {e.nombre}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="div-wrap-footer">
            <h2 className="footer-heading">
              Publicar un evento en la agenda PUCP
            </h2>
            <div className="pb-15">
              Consulta los requisitos en los siguientes enlaces:
            </div>
            <div>
              {terminosycondiciones && (
                <a
                  href={terminosycondiciones.valor}
                  target="_blank"
                  rel="noreferrer"
                  className="footer-link new-button"
                >
                  Ver condiciones
                </a>
              )}
            </div>
            {enviaTuEvento && (
              <a
                href={terminosycondiciones.valor}
                target="_blank"
                rel="noreferrer"
                className="btn-blue w-button new-button"
              >
                Envía tu evento
              </a>
            )}
          </div>
          <div className="div-wrap-footer">
            <h2 className="footer-heading">SOBRE LA PUCP</h2>
            <div className="pb-15">PONTIFICIA UNIVERSIDAD CATÓLICA DEL PERU<br/>RUC: 20155945860
            </div>
          </div>
          {redesSociales.length > 0 && (
            <div className="div-wrap-footer">
              <h2 className="footer-heading">Síguenos</h2>
              <div className="wrap-redes">
                {redesSociales.map((r) => (
                  <a
                    key={r.id}
                    href={r.valor}
                    target="_blank"
                    rel="noreferrer"
                    className="link-red w-inline-block new-button"
                  >
                    {r.key === "FACEBOOK" && (
                      <img src={Rfb} loading="lazy" alt="Facebook link" />
                    )}
                    {r.key === "INSTAGRAM" && (
                      <img src={Rig} loading="lazy" alt="Instagram link" />
                    )}
                    {r.key === "LINKEDIN" && (
                      <img src={Rin} loading="lazy" alt="LinkedIn link" />
                    )}
                    {r.key === "TWITTER" && (
                      <img src={Rtw} loading="lazy" alt="Twitter link" />
                    )}
                    {r.key === "YOUTUBE" && (
                      <img src={Ryt} loading="lazy" alt="YouTube link" />
                    )}
                    {r.key === "whatapp" && (
                      <img src={Riw} loading="lazy" alt="WhatsApp link" />
                    )}
                    {r.key === "spotify" && (
                      <img src={Rsp} loading="lazy" alt="WhatsApp link" />
                    )}
                  </a>
                ))}

                {/* <button className="link-red w-inline-block new-button">
                <img src={Rsp} loading="lazy" alt="Spotify link" />
              </button> */}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="line-white"></div>
      <div className="container-1200 w-container">
        <div className="footer-text-copy">
          © Pontificia Universidad Católica del Perú | Todos los derechos
          reservados
        </div>
      </div>
    </footer>
  );
};

export default Footer;
